.container {
    width:30px;
  overflow: hidden;
}

#parallelogram {
  width: 40px;
  height: 10px;
  margin: 0 0 0 -15px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg);
  background: #E68369;
  overflow: hidden;
  position: relative;
}

.container2 {
    width:15px;
    overflow: hidden;
  }
#leftskew{
    width: 20px;
    height: 10px;
    margin: 0 0 0 2px;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    transform: skew(20deg);
    background: #E68369;
    overflow: hidden;
    position: relative;
}